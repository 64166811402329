import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]
const _hoisted_4 = {
  key: 1,
  class: "form-check form-switch"
}
const _hoisted_5 = ["id"]
const _hoisted_6 = ["for"]
const _hoisted_7 = { key: 2 }
const _hoisted_8 = ["for"]
const _hoisted_9 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.setting.type === 'string')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("label", {
          for: _ctx.setting.uid,
          class: "form-label"
        }, _toDisplayString(_ctx.setting.description), 9, _hoisted_2),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data) = $event)),
          id: _ctx.setting.uid,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update', _ctx.data))),
          required: ""
        }, null, 40, _hoisted_3), [
          [_vModelText, _ctx.data]
        ])
      ]))
    : (_ctx.setting.type === 'boolean')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            class: "form-check-input",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data) = $event)),
            type: "checkbox",
            id: _ctx.setting.uid,
            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update', _ctx.data)))
          }, null, 40, _hoisted_5), [
            [_vModelCheckbox, _ctx.data]
          ]),
          _createElementVNode("label", {
            class: "form-check-label",
            for: _ctx.setting.uid
          }, _toDisplayString(_ctx.setting.description), 9, _hoisted_6)
        ]))
      : (_ctx.setting.type === 'number')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("label", {
              for: _ctx.setting.uid,
              class: "form-label"
            }, _toDisplayString(_ctx.setting.description), 9, _hoisted_8),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              class: "form-control",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data) = $event)),
              id: _ctx.setting.uid,
              onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update', _ctx.data))),
              step: "any",
              required: ""
            }, null, 40, _hoisted_9), [
              [
                _vModelText,
                _ctx.data,
                void 0,
                { number: true }
              ]
            ])
          ]))
        : _createCommentVNode("", true)
}