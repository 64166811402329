import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsForm = _resolveComponent("SettingsForm")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      (_ctx.data?.settings)
        ? (_openBlock(), _createBlock(_component_SettingsForm, {
            key: 0,
            settings: _ctx.data?.settings,
            onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.SaveData($event)))
          }, null, 8, ["settings"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading"]))
}